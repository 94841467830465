@import "@Styles/_colors.sass"
@import "@Styles/_text.sass"
@import '@Styles/_breakpoints.sass'

@mixin tableRowSize
    grid-template-columns: minmax(80px, 1fr) minmax(180px, 3fr) minmax(150px, 3fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(100px,160px)
    @include desktop
        grid-template-columns: minmax(80px, 1fr) minmax(180px, 3fr) minmax(200px, 3fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 160px)

.event
    &__info-confirm
        display: flex
        flex-direction: column
        gap: 12px
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12)
        background-color: $colorGrey100
        padding: 12px 24px

        @include desktop
            flex-direction: row
            justify-content: space-between
            align-items: center
        &__buttons
            gap: 24px
            display: flex
            flex-direction: column
            @include desktop
                flex-direction: row
        &__text
            font-family: Roboto, sans-serif
            font-size: 16px
            font-style: normal
            font-weight: 500
            line-height: 150%
            color: $colorBlack
            i
                color: $colorMidnightBlue500
                margin-right: 12px
                width: 24px
                height: 24px

    &__header
        margin: 24px 0
        display: flex
        gap: 24px
        flex-direction: column
        justify-content: space-between

        @include desktop
            margin: 72px 0 56px
            flex-direction: row

        &__left
            display: flex
            flex-direction: column
            gap: 24px
            @include desktop
                flex-direction: row
                gap: 50px
                align-items: center

        &__button
            display: flex
            flex-direction: column
            gap: 24px
            @include desktop
                flex-direction: row
        &__admin-buttons
            display: flex
            flex-direction: column
            gap: 24px
            @include desktop
                flex-direction: row

    &__title
        @extend .text--h2
        margin-bottom: 0

    &__button
        &--attend
            @include touch
                width: 100%
                max-width: 375px
    &__tab
        background-color: $colorGrey200 !important
        font-family: Roboto, sans-serif
        font-size: 16px
        font-style: normal
        font-weight: 400
        line-height: 150%
        color: $colorGrey800
        padding: 19px 38px
        &--active
            background-color: transparent !important
            color: $colorBlack
            font-family: Roboto, sans-serif
            font-size: 16px
            font-style: normal
            font-weight: 700
            line-height: 125%
            letter-spacing: -0.08px

    &__pages
        &__pages
            padding: 16px 0
            @include desktop
                padding: 42px 0

        &__row
            display: grid
            gap: 10px
            grid-template-columns: repeat(auto-fill, minmax(100px, 278px))

        &__item
            position: relative

        &__buttons
            margin-top: 10px
            display: flex
            gap: 5px
            overflow-x: auto

        &__button-transcription
            background: $colorWhite
            color: $colorBlack
            text-align: center
            font-family: Roboto, sans-serif
            font-size: 18px
            font-style: normal
            font-weight: 500
            line-height: 150%
            letter-spacing: -0.18px
            border-radius: 0
            border-bottom: 3px solid transparent
            max-width: 250px
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden
            flex: 0 0 auto
            margin: 3px 0

            &:hover, &:active, &:focus
                background: $colorWhite
                color: $colorBlack
                border-bottom: 3px solid $colorPacificBlue600
            &--active
                border-bottom: 3px solid $colorPacificBlue600

.details
    display: flex
    flex-direction: column
    @include  desktop
        flex-direction: row
        margin: 48px 0
        gap: 48px

    &__img
        background-repeat: no-repeat
        background-size: cover
        margin: 16px 0 40px
        height: 182px
        width: 100%
        max-width: 330px
        background-position: center
        @include desktop
            max-width: 430px
            height: 290px
            margin-top: 0

    &__text
        &--body
            @extend .text--body
        &--title
            @extend .text--h5

    &__description
        margin-bottom: 40px

    &__list-header
        min-height: 62px
        background: $colorGrey300
        display: flex
        align-items: center
        margin-bottom: 16px
        padding: 10px 16px
        flex-direction: column
        justify-content: space-between
        gap: 16px
        @include desktop
            padding: 0px 16px
            flex-direction: row
            margin-bottom: 48px
            height: 82px

    &__list-title
        @extend .text--h4

.teams
    &__top
        width: 100%
        padding: 23px 40px 23px 40px
        background-color: $colorPacificBlue600
        color: $colorGrey100
        font-size: 1rem
        margin-bottom: 32px
        margin-top: 20px
        @include desktop
            margin-top: 80px

        &__buttons
            display: flex
            flex-direction: column-reverse
            gap: 24px
            margin-bottom: 32px
            justify-content: flex-end
            @include desktop
                gap: 32px
                flex-direction: row

    &__select
        &__container
            margin-bottom: 35px

    &__search
        max-width: 510px
        width: 100%
        outline: none
        border: 1px solid transparent
        border-bottom: 1px solid $colorGrey600
        padding: 14px 10px

        &:focus
            border: 1px solid $colorGrey500

        &__container
            margin-bottom: 35px
            display: flex
            gap: 16px
            flex-direction: column
            @include desktop
                justify-content: right
                gap: 48px
                flex-direction: row

    &__item
        background: $colorGrey200
        margin-bottom: 8px
        display: flex
        flex-direction: column
        gap: 16px
        justify-content: space-between
        padding: 16px

        @include desktop
            flex-direction: row
            align-items: center
            padding: 16px 40px

        &__name
            @extend .text--h4

        &__inner
            display: flex
            flex-direction: column
            gap: 16px

            @include desktop
                flex-direction: row
                align-items: center
        &__description
            display: flex
            gap: 16px
            flex-direction: column
            color: $colorGrey800
            @extend .text--h4

            @include desktop
                flex-direction: row
                gap: 24px

        &__text
            margin-right: 16px
            color: $colorGrey800
            font-weight: 400
            @extend .text--h4

        &__number
            min-width: 28px
            text-align: center
            display: inline-block
            @extend .text--h4
            i
                color: $colorError600
        &__button-delete,  &__button-reset
            text-align: center
            @include desktop
                max-width: 160px
                width: 100%

        &__button-reset
            height: 100%
            &-container
                text-align: center
            @include desktop
                padding: 0

        &__alert
            color: $colorError600
            @extend .text--body
            display: flex
            align-items: center
            gap: 8px

    &__title
        @extend .text--h4

    &__table
        &__header
            background-color: $colorGrey800
            border-radius: 4px
            padding: 20px 24px
            display: grid
            margin-bottom: 5px

            @include tableRowSize

            span
                @extend .text--h5
                color: $colorWhite
        &__subheader
            display: grid
            background-color: $colorGrey300
            padding: 20px 24px
            @include tableRowSize
            span
                @extend .text--body
                color: $colorBlack
        &__row
            display: grid
            background-color: $colorGrey100
            padding: 20px 24px
            border: 1px solid $colorGrey200
            border-radius: 4px
            border-bottom: 2px
            align-items: center
            overflow-wrap: break-word
            @include tableRowSize
            &__email
                font-size: smaller
        &__delete
            padding: 5px

    &__boxes
        flex-direction: column
        gap: 16px
        display: flex
        margin-bottom: 48px

    &__box
        background-color: $colorWhite
        border: 1px solid $colorGrey600
        padding: 16px

        &__row
            display: flex
            gap: 32px
            padding: 10px

        &__title
            @extend .text--body
            color: $colorGrey800
            flex: 1 1 50%
        &__text
            @extend .text--body
            flex: 1 1 50%

        &__delete
            display: flex
            gap: 32px
            padding: 10px
            justify-content: center
            

    &__send-email
        display: flex
        flex-direction: column
        gap: 36px
        padding: 20px 24px
        &__title, &__content
            display: flex
            flex-direction: column

        &__input, &__textarea
            border: 1px solid $colorGrey600
            background: $colorWhite
            padding: 15px 16px
            font-size: 16px
            font-family: Roboto, sans-serif
            font-weight: 400
            border-radius: 5px
        &__textarea
            min-height: 132px
        &__label
            @extend .text--body-small

    &__delete-team, &__delete-event, &__random-users
        padding: 24px 19px

    &__add-users
        padding: 24px 19px
        &__team
            @extend .text--body
            font-weight: 600
        &__title
            @extend .text--h4
            margin-bottom: 32px
        &__list
            display: flex
            flex-direction: column
            padding-left: 24px
            gap: 10px
            max-height: 60vh
            overflow-y: scroll

        &__checkbox-container
            display: flex
            gap: 12px
            align-items: center
        &__checkbox
            width: 15px
            height: 15px
        &__name
            margin-bottom: 0px
            @extend .text--body
    &__add-user
        padding: 5px
        background-color: transparent
        margin: 32px auto 48px
        @include desktop
            padding: 48px
            background-color: $colorGrey100
            max-width: 880px
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12)
        &__title
            @extend .text--h3
        &__text-required
            @extend .text--body
            margin-bottom: 40px
        &__select
            background-color: #FFFFFF
            height: 3rem
            border-radius: 5px
            border: 1px solid #999DA1
            color: #010913
            max-width: 190px
            width: 100%
            padding: 0 16px

        &__select-container
            margin: 32px 0
        &__input
            max-width: 730px
            width: 100%
        &__add-button
            padding: 15px 30px
            width: 100%
            text-align: center
            @include desktop
                width: unset

        &__add-container
            text-align: center
            text-align: right
